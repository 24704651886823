.dt-top-bar {
    align-items: center;
    margin-bottom: 8px;
    margin-top: -8px;
}

.dt-top-bar .col-sm-3 {
    margin-top: 8px;
}

.dt-top-bar .btn {
    background-color: #3838d0;
    font-size: 12px;
}

.dt-top-bar .dataTables_filter>label {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    align-content: center;
    flex-wrap: nowrap;
    /* height: 150px; */
}

.dt-top-bar .dataTables_filter>label>input {
    max-height: 24px;
    font-size: 14px;
    padding: 0;
}
