a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}

@media (min-width: 768px) {
  html {
    font-size: 14px;
  }

  input {
    line-height: 14px;
    max-height: 14px;
  }

  li.nav-item>a {
    font-weight: bold;
  }

  h3.d-sm-inline-flex.align-items-end.ml-2 {
    font-weight: bolder;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.rightio-logo {
  width: 30%;
  margin-right: 10px;
}

.nav-item {
  font-size: 1.2rem;
}

.nav-item:hover {
  background-color: rgba(0, 0, 0, 0.118);
  border-radius: 12px;
}