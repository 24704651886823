.rightio-home-logo {
  width: 300px;
  height: 300px;
}
.rightio-home-mini-logo {
  width: 79px;
  height: 79px;
  position: relative;
  left: -13px;
  top: -13px;
}
