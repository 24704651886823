.active-desc::after {
    content: "▼";
    /* margin-left: 38px; */
    position: absolute;
}

.active-asc::after {
    content: "▲";
    /* margin-left: 38px; */
    position: absolute;
}

.pointer {
    cursor: pointer;
}

.header-filter-ticket > input{
    width: 10ch;
}

.header-filter-date-created > input, .header-filter-date-updated > input{
    width: 20ch;
}

.header-filter-updated-by > input, .header-filter-assigned-to > input, .header-filter-reported-by > input{
    width: 6ch;
}